'use client'
import React, { useEffect } from 'react'
import { MixpanelTracking } from './mixpanel-browser-analytics';
import { useSession } from 'next-auth/react';
import { usePathname } from 'next/navigation';

export default function TrackPageView({viewerUserId}:{viewerUserId?: string}) {
  const session = useSession();
  const pathname = usePathname();
  
    useEffect(()=>{
        MixpanelTracking.getInstance().pageViewed({
          pageUrl: window.location.href, 
          viewer_user_id: session.data?.user.id ?? 'anonymous',
          page_name: pathname,
        });
    },);
    
  return (
    <></>
  )
}
